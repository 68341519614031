.invest-form {
  padding-bottom: 254px;
}

@media (max-width: 991.98px) {
  .invest-form {
    padding-bottom: 200px;
  }
}

@media (max-width: 767.98px) {
  .invest-form {
    padding-bottom: 100px;
  }
}

@media (max-width: 575.98px) {
  .invest-form {
    padding-bottom: 31px;
  }
}

.paralax-wrapper{
  position: relative;
  z-index: 1;
  .fs-paralax-wrap{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @media(max-width: 900px){
      display: none;
    }
  }
  .blur-ball{
    background-image: url('/img/pics/blur-ball.svg');
    background-position: center;
    background-size: contain;
    width: 143px;
    height: 143px;
    border-radius: 50%;
    position: absolute;
  }
  .blur-ball-1{
    width: 105px;
    height: 105px;
    bottom: 198px;
    right: calc(50% - 365px);
    z-index: -1;
  }
  .blur-ball-2{
    left: 108px;
    top: 113px;
    width: 140px;
    height: 140px;
    z-index: -1;
    @media(max-width: 1080px){
      left: 30px;
    }
  }
  .fs-paralax-item1{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.contact__block{
  .blur-ball-1{
    width: 105px;
    height: 105px;
    width: 140px;
    height: 140px;
    bottom: 151px;
    right: calc(50% - 410px);
    z-index: -1;
  }
  .blur-ball-2{
    left: 108px;
    top: 113px;
    width: 105px;
    height: 105px;
    z-index: -1;
    @media(max-width: 1080px){
      left: 30px;
    }
  }
}
