.form__ {
  &wrapper {
    @media (min-width: 576px) {
      background: linear-gradient(
        154.95deg,
        rgba(22, 22, 40, 0.0854) -2.32%,
        rgba(22, 22, 40, 0.14) 89.57%
      );
      box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(25px);
      border: 1px solid var(--input-border);
      border-radius: 16px;
      padding: 32px 24px;
    }
  }

  &field-container {
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    &field-columns {
      display: flex;
      margin-bottom: 32px;

      & > .form__field-wrapper {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0;

        &:first-child {
          padding-right: calc(var(--grid-tick-gap) / 2);
        }

        &:last-child {
          padding-left: calc(var(--grid-tick-gap) / 2);
        }
      }
    }
  }

  &field-wrapper {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &.label-center {
      .form__label {
        text-align: center;
      }
    }
  }

  &error {
    display: block;
    padding-top: 8px;
    color: #c84968;
    font-size: 1.4rem;
    line-height: 1.22;
  }

  &label {
    display: block;
    color: var(--text-label);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.19;
    margin-bottom: 8px;
  }

  &input,
  &textarea {
    display: block;
    width: 100%;
    color: var(--text);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
    background: linear-gradient(
      154.95deg,
      rgba(22, 22, 40, 0.0854) -2.32%,
      rgba(22, 22, 40, 0.14) 89.57%
    );
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(25px);
    padding: 16px 24px 14px;
    margin: 0;
    border: 1px solid #313c50;
    border-radius: 12px;
    transition: 0.3s ease;

    &:hover,
    &:focus {
      border-color: var(--input-border-active);
    }

    &.error {
      border-color: #c84968;
    }
  }

  &textarea {
    resize: none;
    height: 110px;
  }

  &checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    gap: 22px;
    margin: 15px auto 23px;
  }

  &checkbox {
    input {
      display: none;

      &:checked {
        & ~ label {
          &:before {
            opacity: 1;
          }

          span {
            &:before {
              content: "";
              display: block;
              width: 16px;
              height: 11px;
              background-image: url(/img/pics/dropdown-checked.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              margin-right: 10px;
            }
          }
        }
      }
    }

    label {
      position: relative;
      display: block;
      padding: 1px;
      border-radius: 25px;
      overflow: hidden;
      user-select: none;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          to right,
          #008dff 0%,
          #0085ff 28.3%,
          #00ffff 100%
        );
        opacity: 0;
        transition: 0.3s ease;
      }

      span {
        position: relative;
        display: flex;
        align-items: center;
        background: #2a3149;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.2;
        padding: 8px 16px;
        z-index: 2;
        border-radius: 25px;
      }
    }
  }

  &dropdown {
    position: relative;

    &.active {
      .form__dropdown-selected {
        .form__input {
          border-color: var(--input-border-active);
        }

        img {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &-selected {
      position: relative;

      .form__input {
        cursor: pointer;
      }

      img {
        display: block;
        position: absolute;
        top: calc(50% + 2px);
        right: 22px;
        width: 12px;
        transform: translateY(-50%);
        transition: 0.3s ease;
      }
    }

    &-wrapper {
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 24px;
        display: block;
        color: var(--input-border);
        font-size: 1.6rem;
        line-height: 1.5;
        transform: translateY(-50%);
        transition: 0.3s ease;
        pointer-events: none;
      }
    }

    &-list {
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
      right: 0;
      max-height: 250px;
      background: var(--input-dropdown-list);
      box-sizing: border-box;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
      padding: 16px 24px;
      border: 1px solid var(--input-border);
      border-radius: 12px;
      overflow-y: auto;
      z-index: 5;

      ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
      }

      li {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          background-color: var(--input-dropdown-item-active);
        }

        &.active {
          &:after {
            content: "";
            width: 16px;
            height: 11px;
            background-image: url(/img/pics/dropdown-checked.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-left: 15px;
          }
        }
      }
    }
  }

  &button-container {
    display: flex;
    justify-content: flex-end;

    &.double {
      justify-content: space-between;

      .button-gradient {
        padding-left: 64px;
        padding-right: 64px;

        @media (max-width: 575.98px) {
          padding-left: 24px;
          padding-right: 24px;

          &.black {
            flex-basis: 136px;
            margin-right: 7px;
          }

          &.blue {
            flex-basis: 184px;
          }
        }
      }
    }

    .button-gradient {
      padding-left: 64px;
      padding-right: 64px;

      @media (max-width: 575.98px) {
      }
    }
  }
}
