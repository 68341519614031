html {
  font-size: 10px !important;
}

body {
  position: relative;
  font-family: ProximaNova, serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--text);
  line-height: 1.5;
}

a {
  &,
  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
