.cross-chain{
    padding-top: 184px;
    color: #fff;
    background: #161628;
    padding-bottom: 112px;

    .fs-paralax-wrap{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .fs-paralax-item1{
        position: absolute;
        top: 12px;
        right: -78px;
        width: 157px;
        height: 157px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .fs-paralax-item2{
        position: absolute;
        bottom: -98px;
        left: 80px;
        width: 108px;
        height: 108px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .ts-paralax-item12{
        position: absolute;
        bottom: -177px;
        right: -43px;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .ts-paralax-item11{
        position: absolute;
        top: 133px;
        left: 80px;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .caption-wrap{
        display: flex;
        margin-bottom: 32px;
    }
    .caption{
        font-size: 48px;
        position: relative;  
        font-weight: 500;
        line-height: 1.6;
        &::after{
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 18.49%, #AA477C 44.76%, #8BFFEA 69.16%, #4089C4 99.18%);
        }
    }
    .cc-content{
        max-width: 855px;
        position: relative;
        z-index: 1;
    }
    .cc-caption{
        font-size: 36px;
        color: #EAEBF4; 
        margin-bottom: 32px;
        line-height: 1.25;
        font-weight: 500;  
        max-width: 615px;
        letter-spacing: 0.2px;
    }
    .cc-description{
        font-size: 18px;
        color: #8899B8;
        margin-bottom: 21px;
        letter-spacing: 0.1px;
        line-height: 1.4;
        max-width: 620px;
    }
    .cc-wrapper{
        position: relative;
    }
    .right-part{
        position: absolute;
        top: -49px;
        right: -39px;
        bottom: 0;
        left: 0;
        display: flex;
        padding-top: 61px;
        padding-right: 39px;  
    }
    .image-wrap{
        max-width: 650px;
        margin-left: auto;
        position: relative;
    }

    &.right-type{
        padding-top: 219px;
        padding-bottom: 205px;
        .cc-content{
            max-width: 624px;
            margin-left: auto;
            margin-right: 0;
        }
        .caption-wrap{
            margin-bottom: 45px;
        }
        .caption{
            font-size: 36px;
            &::after{
                top: 110%;
            }
        }
        .cc-caption{
            font-size: 18px;
            letter-spacing: 0px;
            margin-bottom: 38px;
        }
        .right-part{
            position: absolute;
            top: -143px;
            left: 1px;
            bottom: 0;
            right: 0;
            display: flex;
            
        }
        .image-wrap{
            margin-right: auto;
            margin-left: 0;
        }
    }
    @media(max-width: 1500px){
        .fs-paralax-item1{
            right: -30px;
        }
    }
    @media(max-width: 1400px){
        min-height: none;
        .container{
            max-width: 1310px;
        }
        .right-part{
            padding-right: 0;
            right: 0;
        }
        .fs-paralax-item1{
            top: 12px;
            right: -15px;
            width: 157px;
            height: 157px;
        }
        .ts-paralax-item12{
            right: 0;
        }

    }
    @media(max-width: 1300px){
        .image-wrap{
            max-width: 530px;
        }
        .fs-paralax-item1{
            top: 0;
            width: 139px;
            height: 139px;
        }
        &.right-type .cc-content{
            max-width: 560px;
        }
        
    }
    @media(max-width: 1060px){
        .cc-wrapper{
            display: flex;
            align-items: flex-start;
        }
        .right-part{
            position: static;
        }
        .fs-paralax-item1{
            width: 127px;
            height: 127px;
        }
        .fs-paralax-item2{
            bottom: -35px;
            left: 40px;
        }
       
        &.right-type .right-part{
            position: static;
            order: 1;
            margin-right: 15px;
        }
        &.right-type .cc-content{
            order: 2;
        }
        .ts-paralax-item11{
            top: 50px;
            left: 69px;
            width: 140px;
            height: 140px;
        } 
    }
    @media(max-width: 920px){
        padding-top: 160px;
        padding-bottom: 75px;
        &.right-type{
            padding-top: 110px;
            padding-bottom: 75px;
            .cc-content{
                order: 2;
                transform: translateY(-60px);
                margin: 0 auto;
            }
            .image-wrap{
                margin: 0 auto;
            }
            .right-part{
                margin-right: 0;
            }
        }
        .cc-wrapper{
            flex-direction: column;
            align-items: center;
        }
        .cc-content{
            order: 2;
            transform: translateY(-60px);
            margin: 0 auto;
        }
        .fs-paralax-item2{
            bottom: 120px;
            left: 8px;
        }
        .image-wrap{
            margin: 0 auto;
        }
        .ts-paralax-item12{
            display: none;
        }
    }
    @media(max-width: 600px){
        padding-top: 100px;
        padding-bottom: 10px;
        &.right-type{
            padding-top: 0;
            padding-bottom: 0;
        }
        .fs-paralax-item1{
            width: 19vw;
            height: 19vw;
            right: 0;
        }
        .fs-paralax-item2{
            width: 19vw;
            height: 19vw;
            bottom: 20vw;
        }
        .caption{
            font-size: 36px;
        }
        .cc-caption{
            font-size: 24px;
        }
        .cc-description{
            font-size: 18px;
        }
        .ts-paralax-item11{
            top: 9vw;
            left: 7vw;
            width: 22vw;
            height: 22vw;
        }
    }
    
}

.bottom-section{
    width: 100%;
    background: #161628;; 
    position: relative;
    color: #fff;
    padding-top: 150px;
    padding-bottom: 154px;
    display: flex;
    justify-content: center;
    .content-wrap{
        max-width: 1060px;
        margin: 0 auto;
        padding: 141px 0px 146px 17px;
        position: relative;
        z-index: 1;
    }
    .img-wrap{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        img{
            height: 100%;
        }
    }
    .text-wrap{
        position: relative;
        z-index: 1;
    }
    .fos-caption{
        font-size: 64px;
        width: fit-content;
        margin: 0px auto 71px;
        font-weight: 500;
        position: relative;  
        &::after{
            content: '';
            position: absolute;
            top: 118%;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 18.49%, #AA477C 44.76%, #8BFFEA 69.16%, #4089C4 99.18%);
        }
    }
    .fos-description{
        text-align: center;
        font-size: 32px;
        max-width: 580px;
        margin: 0 auto 4.8rem;
    }
    .fos-buttons{
        display: flex;
        justify-content: center;
        padding-left: 19px;
        .invest-link{
            padding: 12px 64px;
            font-size: 18px;
            font-weight: 500;
        }
    }
    .block-wrap{
        position: relative;
        max-width: 1060px;
        width: 100%;
    }
    .mobile-back{
        display: none;
    }
    .desktop-back{
        display: block;
    }
    @media(max-width: 920px){
        padding-top: 105px;
        padding-bottom: 80px;
        .fos-caption{
            font-size: 48px;
        }
        .fos-description{
            font-size: 25px;
            max-width: 440px;
        }
        
    }
    @media(max-width: 600px){
        .content-wrap{
            padding: 105px 0 86px 0px;
        }
        .text-wrap{
            padding: 0 15px;
        }
        .fos-caption{
            font-size: 36px;
            text-align: center;
        }
        .fos-description{
            font-size: 24px;
        }
        .mobile-back{
            display: block;
        }
        .desktop-back{
            display: none;
        }
    }
    
}