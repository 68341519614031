.portfolio-main{
    position: relative;
    z-index: 0;
}
.portfolio{
    padding-top: 178px;
    color: #fff;
    background: #161628;
    position: relative;
    z-index: 1;
    .caption-wrap{
        display: flex;
        margin-bottom: 32px;
    }
    .caption{
        font-size: 48px;
        position: relative;  
        font-weight: 500;
        line-height: 1.7;
        &::after{
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 18.49%, #AA477C 44.76%, #8BFFEA 69.16%, #4089C4 99.18%);
        }
    }
    .filters{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 19px;
    }
    .filter-sign{
        font-size: 16px;
        margin-right: 11px;
        margin-bottom: 10px;
    }
    .filter-btn{
        font-size: 16px;
        color: #58667E;
        border: 1px solid #313C50;
        border-radius: 33px;
        padding: 7px 24px;
        display: block;
        background: #161628;
        transition: .3s;
        
    }
    .filter-btn-wrap{
        padding: 2px;
        border-radius: 33px;
        margin-right: 8px;
       
        transition: 0.3s;
        margin-bottom: 10px;
        position: relative;
        border-radius: 33px;
        z-index: 1;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: transparent;
            opacity: 0;
            border-radius: 33px;
            z-index: -1;
        }
        &:hover{
            &::before{
                background:  linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 103.56%);
                opacity: 1;
            }
            .filter-btn{
                background: #242425;
                color: #FFFFFF;
                border-color: transparent;
            }
        }
        &.active{
            &::before{
                background:  linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 103.56%);
                opacity: 1;
            }
            .filter-btn{
                background: #242425;
                color: #FFFFFF;
                border-color: transparent;
            }
        }
        
    }
    .pf-list{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-bottom: 150px;
    }

    .tegs{
        display: flex;
        margin-bottom: 31px;
        margin-right: auto;
    }
    .item-teg{
        background: #313C50;
        border-radius: 4px;
        padding: 4px 8px;
        color: #54B3FF;
        font-size: 16px;
        margin-right: 8px;
        display: none;
        &.tegOn{
            display: block;
        }
    }
    .item-wrap{
        width: calc(100% / 3 - 24px);
        margin-right: 36px;
        border-radius: 16px;
        transition: 0.2s;
        position: relative;
        margin-bottom: 36px;
        &:nth-child(3n){
            margin-right: 0;
        }
        &:hover{
            .item-info-content{

                opacity: 1;
                pointer-events: all;
            }
            .item-info{
                border: 1px solid #313C50;
                z-index: 3;
                padding-top: 57%;
            }
            .pf-item{
                border-top: 1px solid #313C50;
                border-right: 1px solid #313C50;
                border-left: 1px solid #313C50;
                z-index: 4;
            }
            .pf-social{
                opacity: 1;
            }
            @media(max-width: 900px){
                .item-info{
                    border: 1px solid transparent;
                    z-index: 0;
                    padding-top: 0;
                }
                .pf-item{
                    border-top: 1px solid transparent;
                    border-right: 1px solid transparent;
                    border-left: 1px solid transparent;
                    z-index: 1;
                }
                .pf-social{
                    opacity: 0;
                }
            }
        }
        &.mobile-active{
            
            @media(max-width: 900px){
                .item-info-content{

                    opacity: 1;
                    pointer-events: all;
                }
                .item-info{
                    border: 1px solid #313C50;
                    z-index: 3;
                    padding-top: 57%;
                }
                .pf-item{
                    border-top: 1px solid #313C50;
                    border-right: 1px solid #313C50;
                    border-left: 1px solid #313C50;
                    z-index: 4;
                }
                .pf-social{
                    opacity: 1;
                }
            }
        }
        &.denided{
            display: none;
        }
    }
    .pf-item{
        background: #20203D;
        border-radius: 16px;
        padding: 24px 24px 8px 24px;
        position: relative;
        z-index: 2;
        border: 1px solid transparent;
        transition: 0.2s;
        min-height: 241px;
        display: flex;
        flex-direction: column;
    }
    .pf-social{
        margin-left: auto;
        margin-top: auto;
        width: fit-content;
        transition: .3s;
        opacity: 0;
        display: flex;
    }
    .item-info{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 0;
        z-index: 1;
        border: 1px solid transparent;
        border-radius: 16px;
        background: linear-gradient(154.6deg, rgba(22, 22, 40, 0.9) -2.23%, rgba(22, 22, 40, 0.9) 95.65%);
        backdrop-filter: blur(20px);
        transition: 0.2s;
        
    }
    .item-info-content{
        padding: 24px;
        border-radius: 16px;
        transition: 0.2s;
        opacity: 0;
        pointer-events: none;
    }
    .pf-social-item{
        width: 32px;
        margin-right: 5px;
        cursor: pointer;

        &:not(.active) {
            display: none;
        }
        &:last-of-type{
            margin-right: 0;
        }
        svg{
            path{
                fill: white;
                fill-opacity: 0.7;
                transition: 0.3s;
            }
            rect{
                fill: #2A3149;
                transition: 0.3s;
            }
        }
        &:hover{
            svg{
                path{
                    fill-opacity: 1;
                }
                rect{
                    fill: #54B3FF;
                }
            } 
        }
        
    }
    .pf-item-logo{
        max-width: 284px;
        margin: 0 auto;
        height: 80px;
        width: 248px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-height: 80px;
            max-width: 248px;
            width: auto;
        }
    }
    .item-caption{
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .item-document{
        font-size: 16px;
        color: #4089C4;
        margin-bottom: 18px;
        padding-left: 9px;
        span{
            margin-left: 6px;
        }
    }
    .item-description{
        font-size: 16px;
        color: #8899B8;
        margin-bottom: 17px;
    }
    .item-address{
        font-size: 16px;
        color: #58667E;
    }
    .item-address-link{
        color: #58667E;
        &:hover{
            color: #54b3ff;
        }
    }
    @media(max-width: 1400px){
        .container{
            max-width: 1310px;
        }
        .item-wrap{
            &:hover{
                .item-info{
                    padding-top: 241px;
                }
            }
        }
        
        
    }
    @media(max-width: 1050px){
        .item-wrap{
            width: calc(50% - 12px);
            margin-right: 24px;
            margin-bottom: 24px;
            &:nth-child(3n){
                margin-right: 24px;
            }
            &:nth-child(2n){
                margin-right: 0;
            }
        }
    }
    @media(max-width: 720px){
        .item-wrap{
            width: 100%;
            margin-right: 0;
        }
    }
    
}