.third-screen{
    width: 100%;
    background: linear-gradient(104.32deg, #161628 -3.68%, #1C1C39 103.47%); 
    position: relative;
    padding-top: 168px;
    padding-bottom: 170px;
    color: #fff;
    .caption-wrap{
        display: flex;
        justify-content: center;
    }
    .ts-caption{
        font-size: 64px;
        text-align: center;
        margin-bottom: 4.8rem;
        position: relative;  
        font-weight: 500;
        line-height: 1.7;
        &::after{
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 18.49%, #AA477C 44.76%, #8BFFEA 69.16%, #4089C4 99.18%);
        }
    }
    .ts-description{
        font-size: 24px;
        line-height: 1.5;
        max-width: 76rem;
        text-align: center;
        color: #8899B8;
        margin: 0 auto 21rem;
    }
    .block{
        position: relative;
    }
    .img-part{
        position: absolute;
        max-width: 38rem;
    }
    .block1{
        max-width: 88rem;
        padding-left: 33rem;
        padding-top: 11.8rem;
        margin-bottom: 16.6rem;
        margin-right: auto;
        .img-part{
            top: 0;
            left: 0;
        }
    }
    .block2{
        max-width: 84rem;
        padding-right: 27rem;
        padding-top: 10.8rem;
        padding-bottom: 8rem;
        margin-left: auto;
        .img-part{
            top: 0;
            right: 0;
        }
        .block-caption{
            margin-bottom: 28px;
        }
    }
    .text-part{
        position: relative;
        z-index: 1;
    }
    
    .block-caption{
        font-size: 36px;
        margin-bottom: 16px;
    }
    .block-content{
        font-size: 24px;
        line-height: 1.5;
        color: #FFFFFF;
    }
    .bold{
        font-weight: bold;
    }
    .ts-paralax-wrap{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .ts-paralax-item11{
        position: absolute;
        top: 59px;
        left: -121px;
        width: 245px;
        height: 245px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .ts-paralax-item21{
        position: absolute;
        top: 89px;
        right: -122px;    
        width: 245px;
        height: 245px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .ts-paralax-item12{
        position: absolute;
        top: 49px;
        right: -473px;
        width: 143px;
        height: 143px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .ts-paralax-item22{
        position: absolute;
        top: 309px;
        left: -437px;
        width: 143px;
        height: 143px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    @media(max-width: 1600px){
        .container{
            max-width: none;
            padding: 0 155px;
        }
        .block1{
            max-width: none;
            .text-part{
                max-width: 88rem;
            }
        }
        .ts-paralax-item12{
            right: -60px;
        }
        .block2{
            max-width: none;
                padding-right: 0;
            .text-part{
                max-width: 84rem;
                margin-left: auto;
                padding-right: 27rem;
            }
        }
        .ts-paralax-item22{
            left: 0;
        }
    }
    @media(max-width: 1400px){
        padding-top: 120px;
        padding-bottom: 150px;
        .ts-caption{
            font-size: 56px;
        }
        .ts-description{
            margin-bottom: 14rem;
        }
        .ts-paralax-item22{
            left: -80px;
        }
    }
    @media(max-width: 1080px){
        .ts-paralax-item21{
            top: 65px;
            right: -53px;
            width: 173px;
            height: 173px;
        }
        .ts-paralax-item22{
            left: 0;
            display: none;
        }
        .ts-paralax-item11{
            width: 173px;
            height: 173px;
            top: 59px;
            left: -50px;
        }  
        .ts-paralax-item12{
            width: 107px;
            height: 107px;
            right: -20px;
        }  
        .container{
            padding: 0px 70px;
        }
        .ts-caption{
            font-size: 42px;
        }
        .ts-description{
            font-size: 18px;
            max-width: 540px;
        }
        .block-caption{
            font-size: 28px;
        }
        .block-content{
            font-size: 18px;
        }
        .img-part{
            max-width: 28rem;
        }
        
        .block1{
            padding-top: 7.8rem;
             margin-bottom: 13.6rem;
             padding-left: 0;
            .text-part{
                max-width: 70rem;
                padding-left: 25rem;
            }
        }
        .block2{
            padding-top: 5.8rem;
            padding-bottom: 8rem;
            .text-part{
                max-width: 64rem;
                padding-right: 21rem;
            }
        }
    }
    @media(max-width: 700px){
        .ts-caption{
            font-size: 28px;
        }
        .ts-description{
            font-size: 16px;
           
        }
        .block-caption{
            font-size: 18px;
        }
        .block-content{
            font-size: 16px;
        }
        .block1{
            padding-top: 21.8rem;
            margin-bottom: 5.6rem;
            .text-part{
                max-width: none; 
                padding-left: 0;
            }
            .img-part{
                left: 35px;
            }
        }
        .block2{
            padding-top: 22.8rem;
            padding-bottom: 0;
            .text-part{
                max-width: none; 
                padding-right: 0;
            }
            .img-part{
                right: 35px;
            }
        }
    }
    @media(max-width: 520px){
        padding-top: 90px;
        padding-bottom: 90px;
        .container{
            padding: 0 20px;
        }
        .ts-paralax-item21{
            top: 33px;
            right: 0;
            width: 30vw;
            height: 30vw;
        }
        .ts-paralax-item11{
            width: 30vw;
            height: 30vw;
            top: 55px;
            left: -26px;
        }  
        .ts-paralax-item12{
            width: 16vw;
            height: 16vw;
            right: 0;
        }
        .ts-description{
            margin-bottom: 5rem;
        }
        .img-part{
            width: 55%;
        }
        .block {
            padding-top: 40%;
        }
        .block-caption{
            margin-bottom: 0px;
        }
    }
}


