.thank-screen{
    color: #FFFFFF;
    background: #161628;
    padding-top: 81px;
    .text-wrap{
        max-width: 500px;
        margin: 0 auto;
        transform: translateY(-140px);
    }
    .thank-caption{
        font-size: 36px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 16px;
    }
    .thank-description{
        font-size: 24px;
        color: #8899B8;
        text-align: center;
        margin-bottom: 32px;
        font-weight: 500;
    }
    .go-back-link{
        background: linear-gradient(98.33deg, #008DFF -18.68%, #54B3FF 143.44%);
        font-size: 18px;
        border-radius: 40px;
        padding: 13px 38px;
        margin: 0 auto;
        display: block;
        width: fit-content;
        font-weight: 500;
        color: #FFFFFF;
    }
    .thank-container{
        max-width: 1050px;
        margin: 0 auto;
        padding: 0 15px;
        
    }
    .only-desktop{
        display: block;
        @media(max-width: 720px){
            display: none;
        }
    }
    .only-mobile{
        display: none;
        @media(max-width: 720px){
            display: block;
        }
    }
    @media(max-width: 720px){
        .thank-caption{
            font-size: 24px;
            margin-bottom: 12px;
        }
        .thank-description{
            font-size: 18px;
            margin-bottom: 24px;
        }
        .go-back-link{
            font-size: 16px;
        }
        .text-wrap{
            transform: translateY(-100px);
        }
    }
    @media(max-width: 600px){
        padding-bottom: 50px;
        .text-wrap{
            transform: translateY(-40px);
        }
    }
}