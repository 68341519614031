.first-screen{
    width: 100%;
    min-height: 100vh;
    background: url('/img/pics/fs-back.png'); 
    background-size: cover;
    position: relative;
    padding-top: 28.5rem;
    .fs-content{
        max-width: 855px;
        position: relative;
        z-index: 1;
    }
    .caption{
        font-size: 72px;
        color: #EAEBF4; 
        margin-bottom: 4.8rem;  
        line-height: 1.25;
        font-weight: 500;  
    }
    .description{
        font-size: 24px;
        color: #8899B8;
        margin-bottom: 4.8rem; 
        max-width: 638px;
    }
    .fs-buttons{
        display: flex;
        align-items: center;
    }
    .first-button{
        margin-right: 34px;
        padding: 13px 66px;
        font-size: 18px;
        font-weight: 500;
    }
    .fs-wrapper{
        position: relative;
    }
    .right-part{
        position: absolute;
        top: -49px;
        right: -39px;
        bottom: 0;
        left: 0;
        display: flex;
    }
    .image-wrap{
        max-width: 650px;
        margin-left: auto;
        position: relative;
    }
    .fs-paralax-wrap{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .fs-paralax-item1{
        position: absolute;
        top: 35px;
        right: 40px;
        width: 143px;
        height: 143px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
    }
    .fs-paralax-item2{
        position: absolute;
        bottom: -114px;
        left: 24px;
        width: 205px;
        height: 205px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
        z-index: -1;
    }
    .fs-paralax-2-item1{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    .spacer{
        opacity: 0;

    }
    .fs-portfolio-btn{
        padding: 15px 61px;
    }
    @media(max-width: 1450px){
        padding-top: 23.5rem;
    }
    @media(max-width: 1400px){
        .image-wrap{
            max-width: 535px;
        }
        .fs-paralax-item2{ 
            bottom: -48px;
            left: 24px;
            width: 168px;
            height: 168px;
        }
        .fs-paralax-item1{
            width: 117px;
            height: 117px;
        }
        .caption{
            font-size: 56px;
        }
        .fs-content{
            max-width: 600px;
        }
    }
    @media(max-width: 1080px){
        .image-wrap{
            max-width: 480px;
        }
        .fs-paralax-item2{ 
            bottom: -18px;
            width: 151px;
            height: 151px;
        }
        .fs-paralax-item1{
            width: 105px;
            height: 105px;
        }
        .caption{
            font-size: 52px;
        }
        .description{
            font-size: 20px;
            max-width: 520px;
        }
        .fs-content{
            max-width: 600px;
        }
    }
    @media(max-width: 998px){
        padding-top: 18.5rem;
        padding-bottom: 8rem;
        .fs-paralax-item2{ 
            bottom: 20px;
        }
        .right-part{
            position: static;
        }
        .fs-content{
            margin: 0 auto 30px;
        }
        .caption{
            text-align: center
        }
        .description{
            max-width: none;
        }
        .fs-buttons{
            margin: 0 auto;
            width: fit-content;
        }
        .image-wrap{
            margin-right: auto;
        }
    }
    @media(max-width: 700px){
        .caption{
            font-size: 36px;
        }
        .description{
            font-size: 18px;
        }
        .fs-buttons{
            width: 100%;
        }
        .first-button{
            margin-right: 15px;
            padding: 12px 15px;
            width: 100%;
            text-align: center;
        }
        .border-gradient-btn{
            width: 70%;
            .gradient-btn{
                padding: 10px 15px;
                width: 100%;
                text-align: center;
            }
        }
    }
    @media(max-width: 520px){
        min-height: none;
        .fs-paralax-item2{ 
            bottom: 2vw;
            left: 24px;
            width: 29vw;
            height: 29vw;
        }
        .fs-paralax-item1{
            width: 20vw;
            height: 20vw;
        }
    }
}


