.invest-form-progress__block {
  margin-bottom: 48px;
}

.invest-form-progress__container {
  position: relative;
}

.invest-form-progress__line {
  position: absolute;
  top: 5px;
  left: 1px;
  right: 1px;
  background: var(--input-border);
  height: 2px;

  div {
    height: 100%;
    width: 0%;
    background: linear-gradient(
      97.04deg,
      #008dff -16.54%,
      #4089c4 18.49%,
      #aa477c 44.76%,
      #8bffea 69.16%,
      #4089c4 99.18%
    );
    transition: 1s ease;

    &[data-progress="1"] {
      width: 25%;
    }

    &[data-progress="2"] {
      width: 75%;
    }

    &[data-progress="3"] {
      width: 100%;
    }
  }
}

.invest-form-progress__item-wrapper {
  position: relative;
  display: flex;
  z-index: 2;
}

.invest-form-progress__item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:last-child {
    align-items: flex-end;

    p {
      text-align: right;
    }
  }

  &:first-child {
    align-items: flex-start;

    p {
      text-align: left;
    }
  }

  div {
    width: 12px;
    height: 12px;
    background-color: var(--input-border);
    border: 2px solid var(--background);
    border-radius: 50%;
    margin-bottom: 12px;

    &:before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        97.76deg,
        #008dff -33.02%,
        #0085ff 11.39%,
        #00ffff 123.89%
      );
      border-radius: 50%;
      opacity: 0;
    }
  }

  p {
    color: var(--input-border);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
  }

  div:before,
  p {
    transition: 0.3s ease;
  }

  &.active {
    div:before {
      opacity: 1;
    }

    p {
      color: var(--text-subtext);
    }
  }
}

@media (max-width: 575.98px) {
  .invest-form-progress__block {
    margin-bottom: 50px;
  }

  .invest-form-progress__item {
    p {
      word-spacing: 999px;
    }
  }
}
