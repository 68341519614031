* {
  box-sizing: border-box;
}

body {
  background-color: var(--background);

  // &.disable-scroll {
  //   overflow: hidden;
  // }
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

hr {
  margin: 0;
  border: none;
}

input {
  display: block;
  padding: 0;
  border: none;
  background-color: transparent;
}

button:focus,
input:focus,
textarea:focus,
select:focus,
input:invalid,
input:required {
  outline: none !important;
  box-shadow: none !important;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #16162f inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--text) !important;
}

.button-gradient {
  position: relative;
  display: inline-block;
  padding: 16px 36px;
  border-radius: 40px;
  text-align: center;
  transition: 0.3s ease;

  span {
    position: relative;
    color: var(--text);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.22;
    z-index: 4;
  }

  &.rainbow {
    background: linear-gradient(
      to right,
      #008dff 0%,
      #4089c4 29.17%,
      #aa477c 51.04%,
      #8bffea 71.35%,
      #4089c4 96.35%
    );

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to left,
        #008dff 0%,
        #4089c4 29.17%,
        #aa477c 51.04%,
        #8bffea 71.35%,
        #4089c4 96.35%
      );
      border-radius: 40px;
      opacity: 0;
      transition: 0.3s ease;
    }

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      background: linear-gradient(97.48deg, #242425 -17.7%, #2c2d37 112.27%);
      border-radius: 40px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &.blue {
    background: linear-gradient(to right, #008dff 0%, #4089c4 100%);

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 40px;
    }

    &:before {
      background: linear-gradient(
        to right,
        #008dff 0%,
        #0085ff 28.3%,
        #00ffff 100%
      );
    }

    &:after {
      background: linear-gradient(
        to left,
        #008dff 0%,
        #0085ff 28.3%,
        #00ffff 100%
      );
      opacity: 0;
      transition: 0.3s ease;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &.black {
    border: 2px solid var(--input-dropdown-item-active);

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 40px;
      opacity: 0;
      transition: 0.3s ease;
    }

    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to right,
        #008dff 0%,
        #0085ff 28.3%,
        #00ffff 100%
      );
      z-index: 2;
    }

    &:after {
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      background: linear-gradient(97.48deg, #242425 -17.7%, #2c2d37 112.27%);
      z-index: 3;
      transition-delay: 0.2s;
    }

    span {
      color: var(--text-label);
      transition: 0.3s ease;
    }

    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }

      &:before {
        transition-delay: 0.1s;
      }

      &:after {
        transition-delay: 0s;
      }

      span {
        color: var(--text);
      }
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.gradient__line {
  position: relative;
  margin: 0 auto;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      to right,
      #008dff 0%,
      #4089c4 29.17%,
      #aa477c 51.04%,
      #8bffea 71.35%,
      #4089c4 96.35%
    );
  }
}

@media (max-width: 1199.98px) {
  .button-gradient {
    padding: 12px 32px;
    border-radius: 40px;

    span {
      font-size: 1.6rem;
      line-height: 1.2;
    }
  }
}
