.about-mission__block {
  padding: 142px 0 218px;
  background: linear-gradient(109.09deg, #1f1f37 -33.77%, #0d0d1d 114.31%);
  overflow: hidden;

  h2 {
    color: var(--text);
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 164px;
  }
}

.about-mission__section {
  display: flex;
  align-items: center;
  margin-bottom: 126px;

  &:nth-child(2n + 1) {
    .about-mission__image-block {
      justify-content: flex-end;
    }
  }

  &:nth-child(2n + 2) {
    .about-mission__text-block {
      align-items: flex-end;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.about-mission__image-block,
.about-mission__text-block {
  flex: 0 0 50%;
  max-width: 50%;
}

.about-mission__image-block {
  display: flex;
  align-items: center;
}

.about-mission__image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 512px;
  width: 100%;

  img {
    display: block;
    width: 309px;
  }
}

.about-mission__text-block {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 calc(var(--grid-tick-gap) / 2);
  z-index: 2;
}

.about-mission__text {
  max-width: 512px;
  width: 100%;

  h3 {
    font-size: 3.2rem;
    color: var(--text);
    font-weight: 600;
    line-height: 1.22;
    margin-bottom: 16px;
  }

  p {
    color: #8899b8;
    font-size: 2.4rem;
  }
}

@media (max-width: 1199.98px) {
  .about-mission__block {
    padding: 120px 0 150px;

    h2 {
      font-size: 3.6rem;
      line-height: 1.22;
      margin-bottom: 120px;
    }
  }

  .about-mission__section {
    margin-bottom: 100px;
  }

  .about-mission__image {
    max-width: 450px;

    img {
      width: 250px;
    }
  }

  .about-mission__text {
    max-width: 450px;

    h3 {
      font-size: 2.4rem;
      line-height: 1.21;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.55;
    }
  }
}

@media (max-width: 767.98px) {
  .about-mission__block {
    padding: 100px 0 130px;

    h2 {
      margin-bottom: 100px;
    }
  }

  .about-mission__section {
    flex-wrap: wrap;
    margin-bottom: 60px;

    &:nth-child(2n + 1) {
      .about-mission__image-block {
        justify-content: flex-start;
      }
    }

    &:nth-child(2n + 2) {
      .about-mission__image-block {
        order: 1;
        justify-content: flex-end;
      }

      .about-mission__text-block {
        order: 2;
        align-items: flex-end;
      }
    }
  }

  .about-mission__image-block,
  .about-mission__text-block {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-mission__image {
    max-width: unset;
    width: unset;
    margin-bottom: 20px;

    img {
      width: 230px;
    }
  }

  .about-mission__text-block {
    padding: 0;
  }
}


@media (max-width: 575.98px) {
  .about-mission__block {
    padding: 40px 0 80px;

    h2 {
      margin-bottom: 44px;
    }
  }

  .about-mission__section {
    margin-bottom: 40px;
  }

  .about-mission__image {
    margin-bottom: 8px;

    img {
      width: 120px;
    }
  }
}