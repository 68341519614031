.contact__block {
  padding: 203px 0 170px;
}

.contact__title {
  max-width: 551px;
  width: 100%;
  color: var(--text);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  margin: 0 auto 40px;

  a {
    color: var(--link);
  }
}

@media (max-width: 767.98px) {
  .contact__block {
    padding: 200px 0 120px;
  }
}

@media (max-width: 575.98px) {
  .contact__block {
    padding: 145px 0 58px;
  }

  .contact__title {
    margin-bottom: 31px;
  }
}
