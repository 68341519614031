.policy__block {
  padding: 204px 0 266px;

  h1 {
    color: var(--text);
    font-size: 4.8rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 48px;

    .gradient__line {
      &:before {
        bottom: -16px;
      }
    }
  }

  p {
    max-width: 892px;
    width: 100%;
    color: var(--text-subtext);
    font-size: 1.6rem;
    white-space: pre-line;
  }
}

@media (max-width: 1199.98px) {
  .policy__block {
    padding: 200px 0;

    h1 {
      font-size: 3.6rem;
      line-height: 1.22;
      margin-bottom: 48px;

      .gradient__line {
        &:before {
          bottom: -8px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .policy__block {
    padding: 160px 0;

    h1 {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 575.98px) {
  .policy__block {
    padding: 88px 0 120px;

    h1 {
      margin-bottom: 32px;
    }
  }
}
