@keyframes example {
    from {transform: translateY(-150px); }
    to {transform: translateY(0); }
  }

.header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 50px;
    transform: translateY(0);
    &.fixed-header{
        border-bottom: 1px solid #2A3149;
        background-color: #16162f;
        position: fixed;
        transform: translateY(-150px);
        animation-name: example;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-delay: .7s;
        padding: 24px 0px 14px 32px;
    
        .container{
            padding-bottom: 3px;
            padding: 0;
        }
        .logo{
            max-width: 110px;
            margin-left: 6px;
        }
    }
    .header-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header-link{
        color: #E5E5E5;
        margin-right: 38px;
        transition: 0.3s;
        &:hover{
            color: #54B3FF;
        }
        &.active{
            color: #54B3FF;
        }
    }
    .dropdown-toggler{
        color: #E5E5E5;
        margin-right: 20px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        &:hover{
            color: #54B3FF;
            
        }
    }
    .dropdown-toggler-icon{
        margin-left: 10px;
        transition: 0.5s;
        path{
            stroke: #B4B4B4;
        }
    }
    .invest-link{
        display: none;
        &.invest-button{
            display: block;
        }
    }
    .header-invest-link{
        padding: 9px 16px;
    }
    .header-menu{
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;
        margin-right: 23px;
    }
    .areas-link{
        color: #FFFFFF;
    }
    .header-dropdown{
        position: relative;
        margin-right: 16px;
        &:hover{
            .drop-list{
                pointer-events: auto;
                opacity: 1;
                transform: translateY(-1px);
                z-index: 1;
            }
            .dropdown-toggler-icon{
                transform: rotate(180deg);
                path{
                    stroke: #54B3FF;
                }
            }
            .dropdown-toggler{
                 color: #54B3FF;  
            }
            
        }
    }
   
    .drop-list{
        position: absolute;
        top: 110%;
        right: 50%;
        width: 170px;
        background: #171729;
        border: 1px solid #313C50;
        box-sizing: border-box;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        padding: 16px 24px;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(-20px);
        z-index: -1;
        pointer-events: none;
    }
    .drop-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        transition: 0.3s;
        &:hover{
            background: #2A3149;
        }
        &.active{
            .drop-active-icon{
                display: block;
            }
        }

    }
    .drop-active-icon{
        display: none;
    }
    .burger-menu{
        display: none;
        width: 36px;
        height: 24px;
        margin-left: 24px;
        align-items: center;
        position: relative;
        .central-part{
            width: 100%;
            height: 2px;
            background:  #EAECEF;
            opacity: 1;
            transition: .3s;
        }
        &::before, &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background:  #EAECEF;
            /* transform: rotate(45deg); */
            transition: .3s;
            height: 2px;
            width: 100%;
        }
        &::before{
            bottom: auto;
        }
        &::after{
            top: auto;
        }
        &:hover{
            &::before{
                bottom: 0;
                transform: rotate(45deg);
            }
            &::after{
                top: 0;
                transform: rotate(-45deg);
            }
            .central-part{
                opacity: 0;
            }
        }
    }
    .burger-wrapper{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    @media(max-width: 900px){
        border-bottom: 1px solid #2A3149;
        .header-dropdown{
            display: none;
        }
        .header-link{
            display: none;
        }
        .burger-menu{
            display: flex;
        }
        padding: 24px 0px 14px 32px;
    
        .container{
            padding-bottom: 3px;
            padding: 0;
        }
        .logo{
            max-width: 110px;
            margin-left: 6px;
        }
    }
    @media(max-width: 600px){
        padding: 11px 24px;
        &.fixed-header{
            padding: 11px 24px;
        }
        .burger-menu{
            width: 18px;
            height: 12px;
        }
        .logo{
            max-width: 60px;
        }
        .header-menu{
            padding-bottom: 0;
            margin-right: 0;
        }
    }
}
