img{
    width: 100%;
}
.container{
    width: 100%;
    max-width: 1310px;
    margin: 0 auto;
    padding: 15px;
    @media(max-width: 1400px){
        max-width: 1100px;
        padding: 0 30px;
    }
    @media(max-width: 600px){
        padding: 0 21px
    }
}
.border-gradient-btn{
    padding: 2px;
    background:  linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 18.49%, #AA477C 44.76%, #8BFFEA 69.16%, #4089C4 99.18%);
    transition: 0.7s;
    border-radius: 40px;
    .gradient-btn{
        padding: 10px 63px;
        background: linear-gradient(97.48deg, #242425 -17.7%, #2C2D37 112.27%);
        border-radius: 40px;
        color: #FFFFFF;
        display: block;
        font-size: 18px;
        transition: .3s;
        @media(max-width: 600px){
            font-size: 16px;
        }
    }
    &:hover{
        background: linear-gradient(261.71deg, #008DFF -6.71%, #4089C4 27.63%, #AA477C 53.38%, #8BFFEA 77.3%, #4089C4 106.73%);
        .gradient-btn{
            background: linear-gradient(97.48deg, #323335 -17.7%, #3E3F4B 112.27%);
        }
    }
   
}