.invest-form-title__block {
  padding-top: 179px;
  margin-bottom: 12px;
}

.invest-form-title__links {
  padding: 0 var(--grid-tick-gap) 12px;
  margin: 0 calc(0 - var(--grid-tick-gap));
  white-space: nowrap;
  overflow-x: auto;

  &,
  a {
    color: var(--text-label);
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.2;
  }

  a {
    &.active {
      color: var(--text);
      font-size: 4.8rem;
      font-weight: 600;
      pointer-events: none;
    }
  }

  span {
    display: inline-block;
    margin: 0 18px;
  }
}

@media (max-width: 1199.98px) {
  .invest-form-title__links {
    a {
      &.active {
        font-size: 3.6rem;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .invest-form-title__block {
    padding-top: 70px;
  }

  .invest-form-title__links {
    padding: 0 var(--grid-tick-gap-sm) 8px;
    margin: 0 calc(0 - var(--grid-tick-gap-sm));

    &,
    a {
      font-size: 1.8rem;
      line-height: 1.22;
    }

    a {
      &.active {
        font-size: 2.4rem;
        line-height: 1.22;
      }
    }

    span {
      display: inline-block;
      margin: 0 14px;
    }
  }
}
