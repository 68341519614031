.about-main__block {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 900px;
  background-image: url(/img/pics/about-main-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .container-default {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 185px;
    padding-bottom: 30px;
    z-index: 3;
  }

  h1 {
    max-width: 855px;
    width: 100%;
    color: var(--text-heading-main);
    font-size: 4.8rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 64px;
  }

  .gradient__line {
    display: block;
    width: fit-content;

    &:before {
      bottom: -16px;
    }
  }

  p {
    max-width: 638px;
    width: 100%;
    color: var(--text-subtext);
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 48px;
  }

  .button-gradient {
    margin-bottom: 80px;
  }

  .button__learn-more {
    display: flex;
    align-items: center;
    color: var(--text-label);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.22;
    padding: 16px 36px;

    svg {
      display: block;
      margin-left: 15px;
    }
  }
}

.about-main__cube {
  position: absolute;
  z-index: 2;

  img {
    display: block;
  }

  &.left {
    left: 0;
    bottom: 0;

    img {
      width: 362px;
    }
  }

  &.right {
    top: 173px;
    right: 0;

    img {
      width: 348px;
    }
  }
}

@media (max-width: 1199.98px) {
  .about-main__block {
    min-height: unset;

    .container-default {
      padding-bottom: 80px;
    }

    h1 {
      font-size: 3.6rem;
      line-height: 1.22;
      margin-bottom: 56px;
    }

    .gradient__line {
      &:before {
        bottom: -24px;
      }
    }

    p {
      max-width: 420px;
      font-size: 1.8rem;
      line-height: 1.55;
      margin-bottom: 32px;
    }
  }

  .about-main__cube {
    &.left {
      bottom: 40px;

      img {
        width: 200px;
      }
    }

    &.right {
      top: 173px;

      img {
        width: 200px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .about-main__block {
    background: unset;

    .container-default {
      padding-bottom: 40px;
    }
  }

  .about-main__cube {
    &.left {
      bottom: 40px;

      img {
        width: 130px;
      }
    }

    &.right {
      top: 140px;

      img {
        width: 100px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .about-main__block {
    .container-default {
      padding-top: 97px;
    }
  }

  .about-main__cube {
    &.left {
      bottom: 26.5%;
      left: -24px;

      img {
        width: 110px;
      }
    }

    &.right {
      top: 27%;
      right: -28px;

      img {
        width: 110px;
      }
    }
  }
}
