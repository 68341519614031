.about-analytics__block {
  padding: 142px 0 114px;
  overflow: hidden;
}

.about-analytics__title {
  margin-bottom: 70px;

  h2 {
    color: #ffffff;
    font-size: 4.8rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 33px;
  }

  p {
    max-width: 474px;
    width: 100%;
    color: var(--text-subtext);
    font-size: 2.4rem;
  }
}

.about-analytics__path {
  display: flex;
  flex-wrap: wrap;
}

.about-analytics__path-item {
  flex: 0 0 25.7%;
  max-width: 25.7%;
  margin-bottom: 81px;

  @media (min-width: 992px) {
    &:nth-child(4n + 4) {
      flex: 0 0 22.9%;
      max-width: 22.9%;

      @media (min-width: 1352px) {
        &:not(&:last-child) {
          .about-analytics__path-number-wrapper {
            &:before {
              width: calc(
                100% - 62px +
                  (100vw - calc(var(--grid) - var(--grid-tick-gap) * 2)) / 2
              );
            }
          }
        }
      }
    }
  }

  @media (min-width: 1352px) {
    &:nth-child(4n + 1):not(&:first-child) {
      .about-analytics__path-number-wrapper {
        &:after {
          content: "";
          right: 100%;
          width: calc(
            (100vw - calc(var(--grid) - var(--grid-tick-gap) * 2)) / 2
          );
        }
      }
    }
  }

  p {
    color: var(--text);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.22;
    min-height: calc(1.8rem * 1.22 * 2);
    padding-right: var(--grid-tick-gap);
  }
}

@media (min-width: 576px) {
  .about-analytics__path-number-wrapper {
    position: relative;
    margin-bottom: 27px;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 4px;
      width: calc(100% - 62px);
      background: linear-gradient(
        to right,
        rgba(64, 137, 196, 0.47) 0%,
        rgba(64, 137, 196, 0) 100%
      );
    }

    &:before {
      content: "";
      left: 62px;
    }
  }
}

.about-analytics__path-number {
  position: relative;
  width: 62px;
  height: 62px;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 50%;

  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    background: linear-gradient(#008dff 0%, rgba(64, 137, 196, 0.21) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  }
}

.about-analytics__path-number-inner {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  border-radius: 50%;

  span {
    color: var(--text);
    font-size: 2.4rem;
    font-weight: bold;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }

  &:before {
    background-color: rgba(255, 255, 255, 0.07);
  }

  &:after {
    background: rgba(255, 255, 255, 0.07);
  }
}

@media (max-width: 1351.98px) and (min-width: 992px) {
  .about-analytics__path-item {
    &:nth-child(4n + 4) {
      &:not(&:last-child) {
        .about-analytics__path-number-wrapper {
          &:before {
            width: calc(100% - 62px + var(--grid-tick-gap));
          }
        }
      }
    }

    &:nth-child(4n + 1):not(&:first-child) {
      .about-analytics__path-number-wrapper {
        &:after {
          content: "";
          right: 100%;
          width: var(--grid-tick-gap);
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .about-analytics__block {
    padding: 90px 0 80px;
  }

  .about-analytics__title {
    margin-bottom: 50px;

    h2 {
      font-size: 3.6rem;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 991.98px) and (min-width: 768px) {
  .about-analytics__block {
    padding: 90px 0 70px;
  }

  .about-analytics__path-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 70px;

    &:nth-child(3n + 3) {
      flex: 0 0 33.33%;
      max-width: 33.33%;

      &:not(&:last-child) {
        .about-analytics__path-number-wrapper {
          &:before {
            width: calc(100% - 62px + var(--grid-tick-gap));
          }
        }
      }
    }

    &:nth-child(3n + 1):not(&:first-child) {
      .about-analytics__path-number-wrapper {
        &:after {
          content: "";
          right: 100%;
          width: var(--grid-tick-gap);
        }
      }
    }
  }
}

@media (max-width: 767.98px) and (min-width: 576px) {
  .about-analytics__block {
    padding: 70px 0 50px;
  }

  .about-analytics__path-item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 50px;

    &:nth-child(2n + 2) {
      flex: 0 0 50%;
      max-width: 50%;

      &:not(&:last-child) {
        .about-analytics__path-number-wrapper {
          &:before {
            width: calc(100% - 62px + var(--grid-tick-gap));
          }
        }
      }
    }

    &:nth-child(2n + 1):not(&:first-child) {
      .about-analytics__path-number-wrapper {
        &:after {
          content: "";
          right: 100%;
          width: var(--grid-tick-gap);
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .about-analytics__block {
    padding: 48px 0;
  }

  .about-analytics__title {
    margin-bottom: 32px;

    h2 {
      font-size: 2.4rem;
      line-height: 1.21;
      margin-bottom: 24px;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.55;
    }
  }

  .about-analytics__path-item {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
      min-height: unset;
      padding-left: var(--grid-tick-gap-sm);
      padding-right: 0;
    }

    &:last-child {
      padding-top: 24px;
      justify-content: center;
    }
  }
}
