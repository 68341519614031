.about-services__block {
  padding: 142px 0 144px;
}

.about-services__title {
  margin-bottom: 141px;

  h2 {
    color: #ffffff;
    font-size: 4.8rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 33px;
  }

  p {
    max-width: 474px;
    width: 100%;
    color: var(--text-subtext);
    font-size: 2.4rem;
  }
}

.about-services__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 83px;
  overflow: hidden;
}

.about-services__item {
  flex: 0 0 25%;
  max-width: 25%;

  img {
    display: block;
    width: 169px;
    margin: 0 auto 41px;
  }

  p {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
  }
}

.about-services__button-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 1199.98px) {
  .about-services__block {
    padding: 120px 0;
  }

  .about-services__title {
    margin-bottom: 100px;

    h2 {
      font-size: 3.6rem;
      line-height: 1.22;
      margin-bottom: 33px;
    }
  }

  .about-services__container {
    margin: 0 -15px 80px;
  }

  .about-services__item {
    img {
      width: 150px;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.375;
      max-width: 200px;
    }
  }
}

@media (max-width: 767.98px) {
  .about-services__block {
    padding: 90px 0;
  }

  .about-services__title {
    margin-bottom: 70px;
  }

  .about-services__container {
    flex-wrap: wrap;
    margin: 0 0 80px;
  }

  .about-services__item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 40px;

    @media (min-width: 576px) {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .about-services__block {
    padding: 32px 0 100px;
  }

  .about-services__title {
    margin-bottom: 53px;

    h2 {
      font-size: 2.4rem;
      line-height: 1.21;
      margin-bottom: 24px;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.55;
    }
  }

  .about-services__container {
    margin: 0 0 40px;
  }

  .about-services__item {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 98px;
      margin: 0;
    }

    p {
      max-width: unset;
      text-align: left;
      padding-left: 24px;
    }
  }
}
