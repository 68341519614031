.second-screen{
    width: 100%;
    background: #171628;; 
    position: relative;
    padding-top: 15.8rem;
    padding-bottom: 20.5rem;
    .ss-content{
        max-width: 855px;
        position: relative;
        z-index: 1;
    }
    .caption-wrap{
        display: flex;
    }
    .caption{
        font-size: 64px;
        font-weight: 600;
        color: #EAEBF4; 
        margin-bottom: 4.8rem;  
        position: relative;  
        &::after{
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(97.04deg, #008DFF -16.54%, #4089C4 18.49%, #AA477C 44.76%, #8BFFEA 69.16%, #4089C4 99.18%);
        }
    }
    .description{
        font-size: 24px;
        color: #8899B8;
        margin-bottom: 4.9rem;
        max-width: 620px;
    }
    .ss-buttons{
        display: flex;
        align-items: center;
        
    }
    .ss-wrapper{
        position: relative;
    }
    .right-part{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        z-index: 0;
    }
    .image-wrap{
        max-width: 587px;
        margin-left: auto;
        padding-top: 10px
    }

    .fs-portfolio-btn{
        padding: 15px 61px;
    }
    @media(max-width: 1400px){
        padding-top: 7.5rem;
        padding-bottom: 15.5rem;
        .image-wrap{
            max-width: 390px;
        }
        .caption{
            font-size: 48px;
        }
        .ss-content{
            max-width: 620px;
        }
        
    }
    @media(max-width: 1080px){
        .image-wrap{
            max-width: 360px;
        }
        .caption{
            font-size: 42px;
        }
        .description{
            font-size: 18px;
            max-width: 540px;
        }
        
    }
    @media(max-width: 998px){
        .ss-content{
            margin-bottom: 60px;
        }
        .description{
            max-width: none;
        }
        .ss-buttons{
            width: fit-content;
            margin: 0 auto;
        }
        .right-part{
            top: -40px;
            
        }
        .image-wrap{
            max-width: calc(10px + 30vw);
            padding-top: 10px;
        }
    }
    @media(max-width: 700px){
        .caption{
            font-size: 28px;
        }
        .description{
            font-size: 16px;
        }
    }
    @media(max-width: 520px){
        padding-bottom: 7.5rem;
    }
}


