.footer{
    padding: 22px 0;
    li{
        display: block;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    background: #1B1B34;
    
    .footer-wrap{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .info-block{
        max-width: 288px;
    }
    .footer-link{
        color: #58667E;
        line-height: 1.9;
        transition: 0.3s;
        &:hover{
            opacity: 0.7;
        }
    }
    .first-list{
        margin-right: 43px;
        .footer-caption{
            margin-bottom: 12px;
        }
    }
    .second-list{
        margin-right: 12px;
        .footer-caption{
            margin-bottom: 2px;
        }
        .social-link{
            line-height: 2.4;
        }
    }
    .footer-caption{
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .info-item{
        color:  #58667E;
        line-height: 1.3;
        margin-bottom: 22px;
    }
    .social-link{
        display: flex;
        align-items: center;
    }
    .social-icon{
        margin-right: 5px;
    }
    @media(max-width: 900px){
        .info-block{
            margin-right: 10px;
        }
    }
    @media(max-width: 600px){
        .footer-wrap{
            flex-direction: column;
        }
        .first-list{
            margin-bottom: 15px;
        }
        
    }
}
