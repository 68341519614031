.forms-page{
    padding-top: 197px;
    padding-bottom: 119px;
    background:  url('/img/pics/forms-back.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .forms-page-wrap{
        display: flex;
    }
    .container{
        position: relative;
    }
    .fs-paralax-wrap{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .blur-ball{
        background-image: url('/img/pics/blur-ball.svg');
        background-position: center;
        background-size: contain;
        width: 143px;
        height: 143px;
        border-radius: 50%;
        position: absolute;
    }
    .blur-ball-1{
        width: 168px;
        height: 168px;
        bottom: 23px;
        right: 10px;
    }
    .blur-ball-2{
        bottom: 28px;
        left: 476px;
        width: 120px;
        height: 120px;
    }
    .blur-ball-3{
        top: 10px;
        left: 5px;
        height: 170px;
        width: 170px;
    }
    .fs-paralax-item1{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .screen-half{
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        &:hover{
            .back-ball{
                transform: scale(1.25);
            }
            .form-link{
                opacity: 1;
            }
            .form-name{
                color: #FFFFFF;
            }
            
        }
    }
    .back-ball{
        position: absolute;
        border-radius: 50%;
        transition: .5s;
        width: 100%;
        padding-top: 100%;  
        transform: scale(0);
        margin: auto;
        z-index: 1;
        background-image: url('/img/pics/big-ball.svg');
        background-size: cover;
    }
    .position-wrap{
        width: 100%;
        padding-top: 100%;  
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 2;
        padding-top: 39%;
    }
    .form-name{
        font-size: 48px;
        text-align: center;
        font-weight: 500;
        color: #8899B8;
        max-width: 450px;
        margin-bottom: 39px;
        line-height: 1.25;
        transition: 0.3s;
    }
    .form-link{
        
        padding: 16px 64px;
        margin: 0 auto;
        display: block;
        opacity: 0;
        transition: .4s;
    }
    @media(max-width: 920px){
        background-image:  none;
        background-repeat: no-repeat;
        padding-bottom: 0;
        .back-ball{
            display: none;
        }
        .position-wrap{
            display: none;
        }
        .content{
            position: static;
            padding-top: 15px;
            margin-bottom: 45px;
        }
        .forms-page-wrap{
            flex-wrap: wrap;
        }
        .screen-half{
            width: 100%;
        }
        .form-link{
            opacity: 1;
        }
        .container{
            padding: 0 0;
        }
        .second-half{
            background-image: url('/img/pics/big-ball-mobile.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position-x: center;
            padding-top: 80px;
            padding-bottom: 80px;
            .form-name{
                color: #FFFFFF;
            }
        }
        .fs-paralax-wrap{
            display: none;
        }
    }
    @media(max-width: 600px){
        .form-name{
            font-size: 24px;
        }
    }
}