.foorth-screen{
    width: 100%;
    background: #161628;; 
    position: relative;
    color: #fff;
    padding-top: 150px;
    padding-bottom: 145px;
    display: flex;
    justify-content: center;
    .content-wrap{
        max-width: 1060px;
        margin: 0 auto;
        padding: 136px 0 126px;
        position: relative;
        z-index: 1;
    }
    .img-wrap{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        img{
            height: 100%;
        }
    }
    .text-wrap{
        position: relative;
        z-index: 1;
    }
    .fos-caption{
        font-size: 64px;
        text-align: center;
        margin-bottom: 4rem;
        font-weight: 500;
    }
    .fos-description{
        text-align: center;
        font-size: 32px;
        max-width: 760px;
        margin: 0 auto 4.9rem;
    }
    .fos-buttons{
        display: flex;
        justify-content: center;
    }
    .block-wrap{
        position: relative;
        max-width: 1060px;
        width: 100%;
    }
    .fos-paralax-wrap-js{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .fos-paralax-item1{
        position: absolute;
        top: 118px;
        left: -117px;
        width: 192px;
        height: 192px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
        z-index: 0;
    }
    .fos-paralax-item2{
        position: absolute;
        bottom: -241px;
        right: -117px;    
        width: 192px;
        height: 192px;
        border-radius: 50%;
        background-image: url('/img/pics/ball.svg');
        background-position: center;
        background-size: contain;
        z-index: 0;
    }
    .fs-portfolio-btn{
        padding: 15px 61px;
    }
    @media(max-width: 1400px){
        .fos-caption{
            font-size: 58px;
        }
        .fos-description{
            font-size: 28px;
            max-width: 640px;
        }
        .block-wrap{
            max-width: 950px;
        }
        .content-wrap{
            padding: 115px 0 100px;
        } 
    }
    @media(max-width: 1220px){
        .block-wrap{
            margin: 0 125px;
        }
        .fos-paralax-item1{
            top: 8vw;
            left: -6vw;
            width: 13vw;
            height: 13vw;
        }
        .fos-paralax-item2{
            width: 13vw;
            height: 13vw;
            bottom: -16vw;
            right: -6.5vw;
        }
    }
    @media(max-width: 1080px){
        .fos-caption{
            font-size: 42px;
        }
        .fos-description{
            font-size: 18px;
            padding: 0 20px;
        }
    }
    @media(max-width: 998px){
        .block-wrap{
            margin: 0px 85px;
        }
        .fos-paralax-item1{
            top: 100px;
            left: -4vw;
        }
        .fos-paralax-item2{
            bottom: -185px;
            right: -4.5vw;
        }
    }
    @media(max-width: 700px){
        .content-wrap{
            padding: 50px 0 53px;
        }
        .fos-caption{
            font-size: 28px;
            margin-bottom: 2rem;
        }
        .fos-description{
            font-size: 16px;
            margin: 0 auto 2.9rem;
        }
        .fos-paralax-item1{
           display: none;
        }
        .fos-paralax-item2{
            display: none;
        }
        
    }
    @media(max-width: 520px){
        padding: 0;
        border-radius: 43px;
        .block-wrap{
            margin: 0;
        }
        .fos-paralax-wrap-js{
            display: none;
        }
    }
}


