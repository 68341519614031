:root {
  --grid: 1280px;
  --grid-tick-gap: 36px;
  --grid-tick-gap-sm: 24px;
  --form-wrapper: 622px;
}

.container-default {
  max-width: calc(var(--grid) + var(--grid-tick-gap) * 2);
  padding: 0 var(--grid-tick-gap);
  width: 100%;
  margin: 0 auto;
}

.container-form {
  max-width: var(--form-wrapper);
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

@media (max-width: 575.98px) {
  .container-default {
    max-width: 100%;
    padding: 0 var(--grid-tick-gap-sm);
  }
}

// @media (max-width: 575.98px) {
//   .container-default,
//   .container-post {
//     padding: 0 15px;
//   }
// }

// @media (min-width: 1400px) {
// }

// @media (max-width: 1199.98px) {
// }

// @media (max-width: 991.98px) {
// }

// @media (max-width: 767.98px) {
// }

// @media (max-width: 575.98px) {
// }
