@font-face {
  font-family: ProximaNova;
  font-display: swap;
  font-weight: 400;
  font-path: "/css/fonts/ProximaNova/ProximaNova-Regular";
}

@font-face {
  font-family: ProximaNova;
  font-display: swap;
  font-weight: 500;
  font-path: "/css/fonts/ProximaNova/ProximaNova-Semibold";
}

@font-face {
  font-family: ProximaNova;
  font-display: swap;
  font-weight: 600;
  font-path: "/css/fonts/ProximaNova/ProximaNova-Bold";
}
