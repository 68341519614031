.mobile-menu{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #161628;
    display: none;
    z-index: 15;
    transform: translateX(100vw);
    transition: 0.3s;
    &.open{
        transform: translateX(0);
    }
    .menu-header{
        border-bottom: 1px solid #2A3149;
        padding: 58px 52px;
        margin-bottom: 30px;
    }
    .menu-link{
        padding: 18px 36px;
        display: block;
        font-size: 24px;
        color: #E5E5E5;
        font-weight: 500;
        margin-bottom: 0;
        &.active{
            color: #54B3FF
        }
    }
    .areas-label{
        padding: 18px 36px;
        font-size: 24px;
        color: #8899B8;
        font-weight: 500;
        margin-bottom: 30px;
    }
    .areas-item{
        display: flex;
        align-items: center;
        padding: 18px 36px;
        &.active{
            .areas-link{
                color: #54B3FF;
            }
            .icon-wrap{
                opacity: 1;
            }
        }
    }
    .areas-link{
        display: block;
        font-size: 24px;
        color: #E5E5E5;
        font-weight: 500;
    }
    .icon-wrap{
        margin-left: 15px;
        opacity: 0;
    }
    .areas-list{
        border-top: 1px solid #2A3149;
        border-bottom: 1px solid #2A3149;
        margin-bottom: 30px;
    }
    .cross{
        position: relative;
        width: 36px;
        height: 36px;
        margin-left: auto;
        &::before, &::after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; 
            background: #EAECEF;
            transform: rotate(45deg);
        }
        &::before{
            height: 2px;
            width: 100%;
        }
        &::after{
            height: 100%;
            width: 2px;
        }
    }
    @media(max-width: 900px){
        display: block;
    }
    @media(max-width: 600px){
        .menu-header{
            padding: 23px 21px;
            margin-bottom: 35px;
        }
        .menu-link{
            padding: 9px 36px;
            font-size: 16px;
            margin-bottom: 17px;
        }
        .areas-label{
            padding: 9px 36px;
            font-size: 16px;
        }
        .areas-item{
            padding: 8px 36px;
        }
        .areas-link{
            font-size: 16px;
        }
        .icon-wrap{
            margin-left: 15px;
        }
        .areas-list{
            margin-bottom: 15px;
        }
        .cross{
            width: 18px;
            height: 18px;
        }
    }
}